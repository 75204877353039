@import url("https://fonts.googleapis.com/css2?family=Graduate&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dosis:wght@200;300;400;500;600;700;800&display=swap");
.component--h1 {
  text-align: center;
}

* {
  margin: 0;
  padding-block-end: 0;
  box-sizing: border-box;
  font-size: 1.2rem;
}

body {
  overflow-x: hidden;
}

a,
a:active,
a:hover {
  text-decoration: none;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
}

.flex {
  display: flex;
  gap: var(--gap, 1rem);
}

.grid {
  display: grid;
  gap: var(--gap, 1rem);
}

.navbar--logo img {
  width: 5.175625rem;
}

.navbar {
  align-items: center;
  justify-content: space-between;
  background-color: yellow;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-inline: auto;
  position: fixed;
  outline: 2px solid blue;
}

.navbar--list {
  margin: 0;
  padding: 0;
  background: hsla(0deg, 0%, 0%, 0.4);
}

.navbar--toggle {
  display: none;
}

@supports (backdrop-filter: blur(1rem)) {
  .navbar--list {
    background: hsla(0deg, 87%, 9%, 0.171);
    backdrop-filter: blur(1rem);
  }
}
@media (max-width: 43.74875rem) {
  .navbar--list {
    --gap: 1.5em;
    position: fixed;
    z-index: 1000;
    inset: 0 0 0 30%;
    padding: min(25vh, 10rem) 2em;
    flex-direction: column;
    transform: translateX(100%);
  }
  .navbar--toggle {
    display: block;
    position: absolute;
    z-index: 9999;
    border: 0;
    top: 2rem;
    right: 2rem;
  }
}
@media (min-width: 43.75rem) {
  .navbar--list {
    margin-inline-end: 1.5rm;
    padding-block: 1.1rem;
    padding-inline: clamp(2rem, 5vw, 5rem) 1rem;
  }
}
@media (min-width: 56.25rem) {
  .navbar--list {
    margin-inline-end: 1.5rm;
    padding-block: 1.1rem;
    padding-inline: clamp(3rem, 10vw, 10rem);
  }
}
.active .navbar--list {
  transform: translateX(0%);
  transition: all 0.3s ease-in-out;
}

.background {
  height: 100vh;
  background-color: yellow;
  outline: 1px solid rgb(20, 244, 117);
}

.background .notice {
  display: flex;
  flex-direction: row-reverse;
  gap: 1rem;
  align-items: center;
  background-color: red;
  padding: 20px;
  margin-inline: auto;
  width: 80%;
  position: relative;
  top: 500px;
}

.background .notice > * {
  flex-shrink: 1;
  flex-basis: 500px;
}

.background .notice--info, .background .notice--button {
  outline: 1px solid green;
}

@media (max-width: 43.74875rem) {
  .background .notice {
    flex-direction: column;
  }
  .background .notice > * {
    flex-shrink: 1;
    flex-basis: auto;
  }
}
.cards--list {
  display: flex;
  gap: 1rem;
}

@media (max-width: 56.24875rem) {
  .cards--list {
    flex-direction: column;
  }
}
.home {
  display: grid;
  background-color: darkorange;
  margin-inline: auto;
}

* {
  outline: 2px dashed red;
}